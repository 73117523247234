<template>
  <div
    class="article-card-header"
    :class="{ 'article-card-header--health': isHealth }"
  >
    <CategoriesList
      v-if="(!isPromo || isHealth) && !hideCategories"
      :categories="categories"
      :is-breadcrumbs="isHealth"
      @clicked-on-category="sendAnalytics"
    />
    <span
      v-if="isDateVisible"
      data-jest="date"
      class="article-card-header__elem article-card-header__elem--date"
    >
      {{ dateText }}
    </span>
  </div>
</template>

<script lang="ts">
  import type { PropType } from 'vue';
  import { getDateText } from '@devhacker/shared/utils/date';
  import CategoriesList from '~/components/CategoriesList.vue';
  import { isHealthPath } from '~/utils/router';
  import { getFullUrl } from '~/utils';

  interface ILhCategoryItem {
    id: number | string;
    title: string;
    url: string;
    icon?: string;
  }

  const CATEGORIES_COUNT = 2;

  export default defineNuxtComponent({
    name: 'ArticleCardHeader',
    components: {
      CategoriesList,
    },
    props: {
      article: {
        type: Object as PropType<IArticle>,
        required: true,
      },
      hideCategories: {
        type: Boolean as PropType<boolean>,
        default: false,
      },
      categoriesCount: {
        type: Number as PropType<number>,
        default: CATEGORIES_COUNT,
      },
      excludedCategoryIds: {
        type: Array as PropType<number[]>,
        default: () => [],
      },
    },
    computed: {
      route() {
        return useRoute();
      },
      categories(): ILhCategoryItem[] {
        if (this.isHealth) {
          return this.article.meta.breadcrumb;
        }

        let categoreisToShow = this.article.categories;

        // Фильтруем категории исключения если есть любая другая
        if (categoreisToShow.length > 1) {
          categoreisToShow = categoreisToShow.filter(
            (item) => !this.excludedCategoryIds.includes(+item.id),
          );
        }

        return categoreisToShow.slice(0, this.categoriesCount);
      },
      isHealth(): boolean {
        return isHealthPath(this.route.path);
      },
      isDateVisible(): boolean {
        const excludePath = ['/reklama/amp/', '/team/amp/', '/about/amp/'];

        return Boolean(this.dateText) && !this.isPromo && !excludePath.includes(this.route.path);
      },
      dateText(): string {
        return getDateText(this.article.date);
      },
      isPromo(): boolean {
        return this.article.isPromo;
      },
    },
    methods: {
      sendAnalytics(url: string, title: string): void {
        this.$sendYandexMetrika({
          level1: 'Клик_Хлебные крошки',
          level4: getFullUrl(url),
          level6: title,
        });
      },
    },
  });
</script>

<style lang="scss" scoped>
  .article-card-header {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;

    &--health {
      align-items: start;
      flex-direction: column;
      gap: 24px;
    }

    &__elem {
      color: #5c5c5c;
      font-family: $mainFont;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-decoration: none;
    }
  }
</style>

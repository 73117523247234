<template>
  <div
    class="categories-list"
    :class="{ 'categories-list--breadcrumbs': isBreadcrumbs }"
  >
    <component
      :is="blockedLink(category.url, index) ? 'span' : 'a'"
      v-for="(category, index) of categories"
      :key="category.id"
      class="categories-list__elem"
      data-jest="category"
      :class="{ 'categories-list__elem--breadcrumb': isBreadcrumbs }"
      :href="blockedLink(category.url, index) ? null : getUrl(category.url)"
      :data-vars-breadcrumb-title="$isAMP ? category.title : undefined"
      @click="$emit('clicked-on-category', category.url, category.title)"
    >
      <span>{{ category.title }}</span>
    </component>
  </div>
</template>

<script lang="ts">
  import type { PropType } from 'vue';
  import getSlugFromUrl from '~/utils/getSlugFromUrl';

  export default defineNuxtComponent({
    name: 'CategoriesList',

    props: {
      categories: {
        type: Array as PropType<IArticleCategory[]>,
        default: () => [],
      },

      isBreadcrumbs: {
        type: Boolean as PropType<boolean>,
        default: false,
      },

      lastBlocked: {
        type: Boolean,
        default: false,
      },
    },

    methods: {
      isCircular(url: string): boolean {
        const route = useRoute();
        const path = route?.path || '';

        // Все пути в здоровье включают в себя префикс главной страницы
        // Делаем доп проверку на этот префикс чтобы крошка главной страницы была кликабельной
        return url === path;
      },
      getUrl(url: string) {
        return getSlugFromUrl(url);
      },
      blockedLink(url: string, idx: number) {
        if (this.lastBlocked) {
          const isLastBreadCrumb = this.categories.length - 1 === idx;
          return isLastBreadCrumb;
        }
        return this.isCircular(url);
      },
    },
  });
</script>

<style lang="scss" scoped>
  .categories-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    overflow: hidden;
    gap: 12px;

    &:not(:last-child) {
      margin-right: 12px;
    }

    &--breadcrumbs {
      gap: 0;
    }

    &__elem {
      color: #5c5c5c;
      font-family: $mainFont;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      text-decoration: none;

      &:not(span) {
        @include hover {
          color: #000000;
        }
      }

      &--breadcrumb {
        &:not(:last-child) {
          margin-right: 0px;

          &:after {
            content: '/';
            margin: 0px 3px;
          }
        }

        &:not(span) {
          @include hover {
            color: var(--main-color);
            &:after {
              color: #5c5c5c;
            }
          }
        }
      }
    }
  }
</style>
